import React, { useState, useEffect } from "react";
import "./DeleteAccount.css";
import Modal from "../common/Modal/Modal";

const API_URL = "https://kleinmanshloma-open-banking-server.onrender.com";

// "http://localhost:8080" ||

const DeleteAccount = () => {
  const [confirmed, setConfirmed] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [error, setError] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [confirmModalActions, setConfirmModalActions] = useState({
    onConfirm: () => {},
    onCancel: () => {},
  });

  const deleteUserAccount = async (userId) => {
    try {
      setShowConfirmModal(true);
      setModalMessage(
        "Are you sure you want to delete your account? This action cannot be undone."
      );

      // Return a new promise that will be resolved when the user confirms or cancels
      return new Promise((resolve, reject) => {
        setConfirmModalActions({
          onConfirm: async () => {
            setShowConfirmModal(false);
            setIsDeleting(true);

            try {
              const token = localStorage.getItem("token");
              const response = await fetch(
                `${API_URL}/api/v1/delete-account/${userId}`,
                {
                  method: "DELETE",
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                }
              );

              const data = await response.json();

              if (!response.ok) {
                throw new Error(data.message || "Failed to delete account");
              }

              if (
                data.openBalance !== 0 &&
                !(Math.abs(data.openBalance) < 0.01)
              ) {
                const isPositiveBalance = data.openBalance > 0;
                setModalMessage(
                  `${data.message} Your account is pending deletion due to an ${
                    isPositiveBalance ? "open balance" : "outstanding credit"
                  } of $${Math.abs(data.openBalance).toFixed(2)}. 
                  ${
                    isPositiveBalance
                      ? "Please contact shloma@bankaccountsinexcel.com to arrange payment."
                      : "Please contact shloma@bankaccountsinexcel.com to receive your refund."
                  }
                  `
                );
                setShowErrorModal(true);
                resolve(data);
              } else {
                setModalMessage("Account deletion in progress.");
                setShowSuccessModal(true);
                resolve(data);
              }
            } catch (error) {
              setModalMessage(
                "There was an error deleting your account. Please try again or contact support."
              );
              setShowErrorModal(true);
              reject(error);
            } finally {
              setIsDeleting(false);
            }
          },
          onCancel: () => {
            setShowConfirmModal(false);
            resolve(null);
          },
        });
      });
    } catch (error) {
      console.error("Error in deleteUserAccount:", error);
      throw error;
    }
  };

  const handleDeleteAccount = async () => {
    if (!confirmed) {
      setShowWarning(true);
      return;
    }

    const userId = localStorage.getItem("userId");
    if (!userId) {
      setError("User ID not found. Please try logging in again.");
      return;
    }

    try {
      await deleteUserAccount(userId);
    } catch (err) {
      setError(err.message);
    }
  };

  if (!isLoggedIn) {
    return (
      <div className="not-logged-in-container">
        <div className="error-message">
          You are not logged in. Redirecting to login page...
        </div>
      </div>
    );
  }

  if (isDeleting) {
    return (
      <div className="deleting-container">
        <h1>Deleting account...</h1>
        <div className="deleting-spinner"></div>
      </div>
    );
  }

  return (
    <>
      <div className="delete-account-container">
        <h1 className="delete-account-title">Delete Account</h1>

        <div className="warning-box">
          Please note: If you have any open balances, your account will remain
          in a pending deletion state and continue to accrue charges until all
          balances are settled.
        </div>

        <div className="info-section">
          <p>Before proceeding with account deletion, please understand:</p>
          <ul>
            <li>This action cannot be undone</li>
            <li>All your data will be permanently deleted</li>
            <li>
              Any open balances must be settled before the deletion is complete
            </li>
          </ul>
        </div>

        <div className="checkbox-container">
          <input
            type="checkbox"
            id="confirm-deletion"
            checked={confirmed}
            onChange={(e) => setConfirmed(e.target.checked)}
          />
          <label htmlFor="confirm-deletion">
            I understand that this action is permanent and cannot be undone
          </label>
        </div>

        {showWarning && !confirmed && (
          <div className="error-message">
            Please confirm that you understand the consequences before
            proceeding
          </div>
        )}

        <button
          className="delete-button"
          onClick={handleDeleteAccount}
          disabled={!confirmed}
        >
          Delete My Account
        </button>
      </div>

      <Modal
        isOpen={showErrorModal}
        type="error"
        title="Pending Deletion"
        message={modalMessage}
        cancelText="Read Message"
        showTimer={true}
        onTimerComplete={() => {
          // Just cleanup and redirect after timer
          setShowErrorModal(false);
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = "/";
        }}
        onCancel={() => {
          // Same cleanup when user clicks
          setShowErrorModal(false);
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = "/";
        }}
      />

      <Modal
        isOpen={showSuccessModal}
        type="success"
        title="Success"
        message={modalMessage}
        cancelText="Close"
        showTimer={true}
        onTimerComplete={() => {
          // Just cleanup and redirect after timer
          setShowSuccessModal(false);
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = "/";
        }}
        onCancel={() => {
          // Same cleanup when user clicks
          setShowSuccessModal(false);
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = "/";
        }}
      />

      <Modal
        isOpen={showConfirmModal}
        type="confirm"
        title="Confirm Deletion"
        message="Are you sure you want to delete your account? This action cannot be undone."
        onConfirm={confirmModalActions.onConfirm}
        onCancel={confirmModalActions.onCancel}
        confirmText="Yes, Delete"
        cancelText="Cancel"
      />
    </>
  );
};

export default DeleteAccount;
