import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import styles from "./login.module.css";

import config from "../../config/config";
import "./LoginDemo.css";

const LoginDemo = () => {
  const [username, setUsername] = useState("demo@example.com");
  const [password, setPassword] = useState("demo@1234");
  const [response, setResponse] = useState(null);
  const [errMessage, setErrMessage] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const navigate = useNavigate();

  const userDataSubmited = async (e) => {
    e.preventDefault();
    const results = await fetch(`${config.URL_PROD}/api/v1/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: username,
        password: password,
      }),
    });

    // Check if the response status is 401 (Unauthorized)
    if (results.status === 401) {
      const data = await results.json();
      setErrMessage(data.message);
      setShowErrorModal(true);
      return;
    }

    // if status 400 than reload the page
    if (results.status === 400) {
      window.location.reload();
      setErrMessage("Something went wrong, please try again");
    }

    // Check if the response status is 429 (Too Many Requests)
    if (results.status === 429) {
      const text = await results.text();
      setErrMessage(text);
      return;
    }

    // Try to parse the response as JSON
    const data = await results.json().catch(() => null);

    if (
      data?.error ||
      results.statusText === "Internal Server Error" ||
      results.statusText === "Unauthorized" ||
      results.statusText === "Bad Request" ||
      !data
    ) {
      setErrMessage(
        data?.message || data?.error || "Unexpected error occurred"
      );
      setShowErrorModal(true);
      return;
    }

    // Save UserID from the data.requisition.User in local storage
    const userId = data?.User;
    localStorage.setItem("userId", userId);
    localStorage.setItem("Requisition Length", data?.Requisition);
    localStorage.setItem("amountOfRequisitions", data?.amountOfRequisitions);
    localStorage.setItem(
      "getExtraUrlForEachAccountNumberForExcel",
      data?.getExtraUrlForEachAccountNumberForExcel
    );
    sessionStorage.setItem("email", data.email);
    sessionStorage.setItem("username", data.username);

    setResponse(data);

    if (data.token && data.Requisition) {
      localStorage.setItem("token", data.token);
      return navigate("/BankAccountsData");
    }

    if (data.token) {
      localStorage.setItem("token", data.token);
      return navigate("/ConnectToBank");
    }
  };

  const downloadExcel = async () => {
    const headerDataArray = [
      "Merchant",
      "Date",
      "Amount",
      "Currency",
      "Mededeling",
      "AccountNumber",
    ];

    const tableDataGB = [
      [
        "Demo Merchant 1",
        "2024-08-10",
        12,
        "GBP",
        "Demo Mededeling 1",
        "GB29NWBK60161331926819",
      ],
      [
        "Demo Merchant 2",
        "2024-08-09",
        -18,
        "GBP",
        "Demo Mededeling 2",
        "GB29NWBK60161331926820",
      ],
      [
        "Demo Merchant 3",
        "2024-08-08",
        -26,
        "GBP",
        "Demo Mededeling 3",
        "GB29NWBK60161331926821",
      ],
      [
        "Demo Merchant 4",
        "2024-08-07",
        972.97,
        "GBP",
        "Demo Mededeling 4",
        "GB29NWBK60161331926822",
      ],
      [
        "Demo Merchant 5",
        "2024-08-06",
        45.5,
        "GBP",
        "Demo Mededeling 5",
        "GB29NWBK60161331926823",
      ],
      [
        "Demo Merchant 6",
        "2024-08-05",
        -123.45,
        "GBP",
        "Demo Mededeling 6",
        "GB29NWBK60161331926824",
      ],
      [
        "Demo Merchant 7",
        "2024-08-04",
        67.89,
        "GBP",
        "Demo Mededeling 7",
        "GB29NWBK60161331926825",
      ],
      [
        "Demo Merchant 8",
        "2024-08-03",
        -34.56,
        "GBP",
        "Demo Mededeling 8",
        "GB29NWBK60161331926826",
      ],
      [
        "Demo Merchant 9",
        "2024-08-02",
        78.9,
        "GBP",
        "Demo Mededeling 9",
        "GB29NWBK60161331926827",
      ],
      [
        "Demo Merchant 10",
        "2024-08-01",
        -12.34,
        "GBP",
        "Demo Mededeling 10",
        "GB29NWBK60161331926828",
      ],
    ];

    const tableDataEuro = [
      [
        "Demo Merchant 11",
        "2024-07-31",
        56.78,
        "EUR",
        "Demo Mededeling 11",
        "BE68539007547044",
      ],
      [
        "Demo Merchant 12",
        "2024-07-30",
        -90.12,
        "EUR",
        "Demo Mededeling 12",
        "BE68539007547045",
      ],
      [
        "Demo Merchant 13",
        "2024-07-29",
        34.56,
        "EUR",
        "Demo Mededeling 13",
        "BE68539007547046",
      ],
      [
        "Demo Merchant 14",
        "2024-07-28",
        -78.9,
        "EUR",
        "Demo Mededeling 14",
        "BE68539007547047",
      ],
      [
        "Demo Merchant 15",
        "2024-07-27",
        12.34,
        "EUR",
        "Demo Mededeling 15",
        "BE68539007547048",
      ],
      [
        "Demo Merchant 16",
        "2024-07-26",
        -56.78,
        "EUR",
        "Demo Mededeling 16",
        "BE68539007547049",
      ],
      [
        "Demo Merchant 17",
        "2024-07-25",
        90.12,
        "EUR",
        "Demo Mededeling 17",
        "BE68539007547050",
      ],
      [
        "Demo Merchant 18",
        "2024-07-24",
        -34.56,
        "EUR",
        "Demo Mededeling 18",
        "BE68539007547051",
      ],
      [
        "Demo Merchant 19",
        "2024-07-23",
        78.9,
        "EUR",
        "Demo Mededeling 19",
        "BE68539007547052",
      ],
      [
        "Demo Merchant 20",
        "2024-07-22",
        -12.34,
        "EUR",
        "Demo Mededeling 20",
        "BE68539007547053",
      ],
    ];
    const data = [headerDataArray, ...tableDataGB, ...tableDataEuro];

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("All Accounts Transactions");
    worksheet.addRows(data);

    const columnSizeForTransactions = [
      { width: 20 },
      { width: 12 },
      { width: 10 },
      { width: 10 },
      { width: 20 },
      { width: 30 },
    ];

    worksheet.columns = columnSizeForTransactions;

    worksheet.getRow(1).font = { bold: true, color: { argb: "FFFFFF" } };

    worksheet.getRow(1).eachCell((cell, colNumber) => {
      if (colNumber <= data[0].length) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "009A44" }, // Green header
        };
      }
    });

    worksheet.eachRow((row, rowIndex) => {
      row.eachCell((cell) => {
        if (rowIndex === 1) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "009A44" }, // Green header
          };
        } else if (rowIndex > 1 && rowIndex % 2 === 0) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "D9F4D6" }, // Light Green for even rows
          };
        }
        // cell.border = {
        //   top: { style: "thin" },
        //   left: { style: "thin" },
        //   bottom: { style: "thin" },
        //   right: { style: "thin" },
        // };
      });
    });

    worksheet.autoFilter = {
      from: { row: 1, column: 1 },
      to: { row: data.length, column: data[0].length },
    };

    // Add a new sheet for balances
    const balanceSheet = workbook.addWorksheet("All Accounts Balances");
    balanceSheet.addRow(["Bank Name", "Iban", "Currency", "Amount", "Pending"]);

    const balanceData = [
      ["WISE", "GB31TRWI2314f284", "GBP", 0.0, 0.0],
      ["WISE", "GB31TRWI2f18284", "CHF", 0.0, 0.0],
      ["WISE", "BE7496721gf7907", "EUR", 0.0, 0.0],
      ["WISE", "US199816", "USD", 215.5, 0.0],
      ["BNP", "BE63001gf8008", "EUR", 37.27, 0.0],
      ["BNP", "BE720gf201416", "EUR", 16.93, 1877.65],
    ];
    balanceSheet.addRows(balanceData);

    balanceSheet.columns = [
      { width: 10 },
      { width: 20 },
      { width: 8 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
    ];

    balanceSheet.getRow(1).font = { bold: true, color: { argb: "FFFFFF" } };
    balanceSheet.getRow(1).eachCell((cell, colNumber) => {
      if (colNumber <= balanceData[0].length) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "009A44" }, // Green header
        };
      }
    });

    balanceSheet.eachRow((row, rowIndex) => {
      row.eachCell((cell) => {
        if (rowIndex === 1) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "009A44" }, // Green header
          };
        } else if (rowIndex > 1 && rowIndex % 2 === 0) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "D9F4D6" }, // Light Green for even rows
          };
        }
      });
    });

    balanceSheet.autoFilter = {
      from: { row: 1, column: 1 },
      to: { row: balanceData.length + 1, column: balanceData[0].length },
    };

    // Add a new sheet for the first account
    const account1Sheet = workbook.addWorksheet("Account 1 Transactions");
    account1Sheet.addRow(headerDataArray);

    account1Sheet.addRows(tableDataGB);

    account1Sheet.columns = columnSizeForTransactions;

    account1Sheet.getRow(1).font = { bold: true, color: { argb: "FFFFFF" } };
    account1Sheet.getRow(1).eachCell((cell, colNumber) => {
      if (colNumber <= tableDataGB.length) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "009A44" }, // Green header
        };
      }
    });

    account1Sheet.eachRow((row, rowIndex) => {
      row.eachCell((cell) => {
        if (rowIndex === 1) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "009A44" }, // Green header
          };
        } else if (rowIndex > 1 && rowIndex % 2 === 0) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "D9F4D6" }, // Light Green for even rows
          };
        }
      });
    });

    account1Sheet.autoFilter = {
      from: { row: 1, column: 1 },
      to: { row: tableDataGB.length + 1, column: tableDataGB[0].length },
    };

    // Add a new sheet for the second account
    const account2Sheet = workbook.addWorksheet("Account 2 Transactions");
    account2Sheet.addRow(headerDataArray);

    account2Sheet.addRows(tableDataEuro);

    account2Sheet.columns = columnSizeForTransactions;

    account2Sheet.getRow(1).font = { bold: true, color: { argb: "FFFFFF" } };
    account2Sheet.getRow(1).eachCell((cell, colNumber) => {
      if (colNumber <= tableDataEuro[0].length) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "009A44" }, // Green header
        };
      }
    });

    account2Sheet.eachRow((row, rowIndex) => {
      row.eachCell((cell) => {
        if (rowIndex === 1) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "009A44" }, // Green header
          };
        } else if (rowIndex > 1 && rowIndex % 2 === 0) {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "D9F4D6" }, // Light Green for even rows
          };
        }
      });
    });

    account2Sheet.autoFilter = {
      from: { row: 1, column: 1 },
      to: { row: tableDataEuro.length + 1, column: tableDataEuro[0].length },
    };

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(blob, "Bankaccountsinexcel.xlsx");
  };

  return (
    <div className="loginPage">
      {response && <p className={styles.responseMessage}>{response.message}</p>}
      {errMessage && (
        <div className="errorMessage">
          <p>{errMessage}</p>
        </div>
      )}
      <div className={styles.excelPreviewContainer}>
        <h2>Excel Preview</h2>
        <div className="excelPreviewMessage">
          <p>
            This is a preview of the Excel file, After simple setup (there is a
            step by step guid).
          </p>
          <p className={styles.smallDisclaimer}>
            <strong>Disclaimer: {""}</strong>
            The format maybe different from the downloaded file.
          </p>
        </div>
        <button
          onClick={downloadExcel}
          className={styles.excelPreviewDownloadButton}
        >
          Download Excel
        </button>
      </div>

      <div className="loginContainer">
        <div className="loginBox">
          <div className={styles.logoSection}>
            <h1>Demo Login</h1>
            <p className={styles.subtitle}>
              Welcome to the demo! Please login to continue.
            </p>
          </div>
          <form onSubmit={userDataSubmited} className={styles.loginForm}>
            <div className={styles.formGroup}>
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                id="username"
                className={styles.input}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                className={styles.input}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className={styles.loginButton}>
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginDemo;
