import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Footer.module.css";

const Footer = () => {
  const navigate = useNavigate();

  const handleLinkClick = (e, path) => {
    e.preventDefault();

    // Scroll to top before navigating
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    // Navigate after scroll animation
    setTimeout(() => {
      navigate(path);
    }, 500);
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <p className={styles.footerText}>
          &copy; {new Date().getFullYear()} BankAccountsInExcel.com All rights
          reserved.
        </p>
        <nav className={styles.footerNav}>
          <a
            href="/terms-and-privacy"
            onClick={(e) => handleLinkClick(e, "/terms-and-privacy")}
          >
            Terms and Privacy
          </a>
          <a href="/contact" onClick={(e) => handleLinkClick(e, "/contact")}>
            Contact
          </a>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
