import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Contact.css";

import config from "../../config/config";

const URL =
  process.env.REACT_APP_ENV === "development"
    ? config.URL_DEV
    : config.URL_PROD;

const Contact = () => {
  // Get stored username and email from session storage
  const [formData, setFormData] = useState({
    name: sessionStorage.getItem("username") || "",
    email: sessionStorage.getItem("email") || "",
    message: "",
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${URL}/send-contact-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log("SUCCESS!", response.status, await response.json());
        setIsSubmitted(true);
      } else {
        console.log("FAILED...", response.status, await response.text());
      }
    } catch (error) {
      console.log("FAILED...", error);
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      const timer = setTimeout(() => {
        navigate(-1); // Navigate back to the previous page
      }, 3000); // 3 seconds delay

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [isSubmitted, navigate]);

  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      {isSubmitted ? (
        <p>Thank you for your message! We will get back to you soon.</p>
      ) : (
        <form onSubmit={handleSubmit} className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit" className="submit-btn">
            Send Message
          </button>
        </form>
      )}
    </div>
  );
};

export default Contact;
