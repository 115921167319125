import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import CopyWrittingForTheApp from "../CopyWrittingForTheApp/CopyWrittingForTheApp";

import styles from "./Signup.module.css";

import config from "../../config/config";

const Signup = () => {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    username: "",
    email: "",
    companyName: "",
    password: "",
    confirmPassword: "",
    approvalCode: "",
  });

  const [errorMessages, setErrorMessages] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState(null);
  const [errorResponse, setErrorResponse] = useState(null);

  const showResponse3Seconds = (duration = 1500, stayOnPage = false) => {
    setTimeout(() => {
      setResponse(null);
      setErrorResponse("");
      if (!stayOnPage) {
        if (duration !== 15000) {
          navigate("/Login");
        }
      }
    }, duration);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };

      if (name === "password" || name === "confirmPassword") {
        if (!updatedData.password || !updatedData.confirmPassword) {
          setErrorMessages("");
        } else if (updatedData.password !== updatedData.confirmPassword) {
          setErrorMessages("Passwords do not match");
        } else {
          setErrorMessages("");
        }
      }

      return updatedData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!agreedToTerms) {
      setErrorMessages(
        "You must agree to the Terms and Privacy Policy to sign up."
      );
      return;
    }

    if (userData.password !== userData.confirmPassword) {
      setErrorMessages("Passwords do not match");
      return;
    }

    // Email validation regex
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if (!emailPattern.test(userData.email)) {
      setErrorMessages("Invalid email address");
      return;
    }

    try {
      const response = await fetch(`${config.URL_PROD}/api/v1/auth/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        setErrorResponse(errorResponse.message);

        // Check if the error is related to the approval code
        if (errorResponse.message === "Approval code is invalid") {
          showResponse3Seconds(1500, true); // Stay on the page
        } else {
          showResponse3Seconds();
        }
        return;
      }

      const result = await response.json();
      setResponse(result?.message);
      showResponse3Seconds(1500); // 3 seconds
    } catch (error) {
      console.error("Network error:", error);
      setErrorResponse("Network error occurred. Please try again.");
      showResponse3Seconds();
    }
  };

  useEffect(() => {
    /* console.log("User Data: ", userData); */
  }, [userData]);

  return (
    <div className={styles.signupPage}>
      <div className={styles.signupContainer}>
        <div className={styles.signupBox}>
          <div className={styles.logoSection}>
            <h1>Bank Accounts in Excel</h1>
            <p className={styles.subtitle}>
              Create your account to get started
            </p>
          </div>

          {response && <p className={styles.responseMessage}>{response}</p>}
          {errorResponse && (
            <p className={styles.errorMessage}>{errorResponse}</p>
          )}

          <form className={styles.signupForm} onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="username">Username</label>
              <input
                id="username"
                type="text"
                name="username"
                className={styles.input}
                placeholder="Choose a username"
                value={userData.username}
                onChange={handleChange}
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="email">Email Address</label>
              <input
                id="email"
                type="email"
                name="email"
                className={styles.input}
                placeholder="you@example.com"
                value={userData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="companyName">Full Name</label>
              <input
                id="companyName"
                type="text"
                name="companyName"
                className={styles.input}
                placeholder="Enter your full name"
                value={userData.companyName}
                onChange={handleChange}
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="password">Password</label>
              <input
                id="password"
                type="password"
                name="password"
                className={styles.input}
                placeholder="Create a strong password"
                value={userData.password}
                onChange={handleChange}
                minLength="8"
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                id="confirmPassword"
                type="password"
                name="confirmPassword"
                className={styles.input}
                placeholder="Confirm your password"
                value={userData.confirmPassword}
                onChange={handleChange}
                required
              />
            </div>

            <div className={styles.approvalCodeContainer}>
              <label htmlFor="approvalCode">Approval Code (Optional)</label>
              <input
                id="approvalCode"
                type="text"
                name="approvalCode"
                className={styles.input}
                placeholder="Enter approval code if you have one"
                value={userData.approvalCode}
                onChange={handleChange}
              />
            </div>

            <div className={styles.termsContainer}>
              <label className={styles.termsLabel}>
                <input
                  type="checkbox"
                  checked={agreedToTerms}
                  onChange={(e) => setAgreedToTerms(e.target.checked)}
                  required
                />{" "}
                I confirm that I have read and agree to the{" "}
                <a
                  href="/terms-and-privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </a>{" "}
                and{" "}
                <a
                  href="/terms-and-privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </label>
            </div>

            {errorMessages && (
              <div className={styles.errorMessage}>{errorMessages}</div>
            )}

            <button
              type="submit"
              className={styles.signupButton}
              disabled={!agreedToTerms} // Disable button if terms are not agreed
            >
              Create Account
            </button>

            <div className={styles.signinLink}>
              <NavLink to="/Login">Already have an account? Sign in</NavLink>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
