import React, { useState, useEffect } from "react";
import styles from "./transactionStyles.module.css";

const parseAdditionalInformation = (additionalInformation) => {
  try {
    if (typeof additionalInformation === "object") {
      return JSON.parse(additionalInformation);
    } else {
      const infoArray = additionalInformation?.split(", ").map((info) => {
        return info.replace(/[\[\]']+/g, "");
      });
      const infoObject = {};

      infoArray?.forEach((info) => {
        const [key, value] = info.split(": ");
        infoObject[key] = value;
      });

      return infoObject;
    }
  } catch (error) {
    console.error("Failed to parse additional information as JSON:", error);
    const infoArray = additionalInformation?.split(", ").map((info) => {
      return info.replace(/[\[\]']+/g, "");
    });
    const infoObject = {};

    infoArray?.forEach((info) => {
      const [key, value] = info.split(": ");
      infoObject[key] = value;
    });

    return infoObject;
  }
};

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 950);
    };

    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);

    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return isMobile;
};

const TransactionTable = ({
  transactions = [],
  account,
  handleSearchTransaction,
  setSearchTransaction,
  sortOrder,
  setSortOrder,
  loading, // Ensure this prop is passed correctly
}) => {
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleAdditionalInformation = (e, transaction) => {
    e.stopPropagation();
    setSelectedTransaction(transaction);
  };

  const closeModal = () => {
    setSelectedTransaction(null);
  };

  useEffect(() => {
    const container = document.querySelector(`.${styles.transactionContainer}`);
    if (!container) return; // Ensure the container exists before adding the event listener

    const handleScroll = () => {
      const scrollPosition = container.scrollTop;
      setIsScrolled(scrollPosition > 50);
    };

    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll); // Cleanup the event listener
    };
  }, [styles.transactionContainer]); // Add styles.transactionContainer as a dependency

  const isMobile = useIsMobile();

  useEffect(() => {
    if (!transactions || transactions.length === 0) {
      setFilteredTransactions([]);
      return;
    }

    let sortedTransactions = [...transactions];
    if (sortOrder === "asc") {
      sortedTransactions.sort(
        (a, b) => new Date(a.bookingDate) - new Date(b.bookingDate)
      );
    } else {
      sortedTransactions.sort(
        (a, b) => new Date(b.bookingDate) - new Date(a.bookingDate)
      );
    }

    if (!handleSearchTransaction) {
      setFilteredTransactions(sortedTransactions);
    } else {
      const trimmedSearchTerm = handleSearchTransaction.trim();
      setFilteredTransactions(
        sortedTransactions.filter((transaction) => {
          const merchant =
            transaction?.Merchant ||
            transaction?.creditorName ||
            transaction?.debtorName ||
            transaction?.proprietaryBankTransactionCode?.replace(/_/g, " ") ||
            transaction?.remittanceInformationUnstructuredArray ||
            (parseAdditionalInformation(
              transaction?.additionalInformation
            )?.narrative?.split(", ")[0] === "BETALING MET DEBETKAART"
              ? "BETALING MET DEBETKAART" +
                (isMobile
                  ? ""
                  : " " +
                    parseAdditionalInformation(
                      transaction?.additionalInformation
                    ).atmPosName)
              : parseAdditionalInformation(
                  transaction?.additionalInformation
                )?.narrative?.split(", ")[0]);

          const merchantName =
            typeof merchant === "string"
              ? merchant.replace(/'/g, "")
              : "Unknown Merchant";

          const transactionAmount =
            transaction?.transactionAmount?.amount?.toString() || "";

          return (
            merchantName
              .toLowerCase()
              .includes(trimmedSearchTerm.toLowerCase()) ||
            transactionAmount.includes(trimmedSearchTerm)
          );
        })
      );
    }
  }, [handleSearchTransaction, transactions, sortOrder, isMobile]);

  if (loading) {
    return <div className={styles.spinner}></div>; // Ensure spinner is displayed when loading
  }

  return (
    <div className={styles.transactionContainer}>
      <div
        className={`${styles.searchAndSortContainer} ${
          isScrolled ? styles.scrolled : ""
        }`}
      >
        <input
          type="text"
          placeholder="Search Transactions"
          className={`${styles.searchInput} ${
            isScrolled ? styles.scrolled : ""
          }`}
          value={handleSearchTransaction}
          onChange={(e) => {
            e.stopPropagation();
            setSearchTransaction(e.target.value);
          }}
        />
        <div
          className={`${styles.sortingContainer} ${
            isScrolled ? styles.scrolled : ""
          }`}
        >
          <label htmlFor="sortOrder" className={styles.sortLabel}>
            Sort by Date:
          </label>
          <select
            id="sortOrder"
            className={styles.sortDropdown}
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
          >
            <option value="desc">Newest First</option>
            <option value="asc">Oldest First</option>
          </select>
        </div>
      </div>

      <div className={styles.transactionCards}>
        {filteredTransactions?.map((transaction, transactionIndex) => {
          const merchant =
            transaction?.Merchant ||
            transaction?.creditorName ||
            transaction?.debtorName ||
            transaction?.proprietaryBankTransactionCode?.replace(/_/g, " ") ||
            transaction?.remittanceInformationUnstructuredArray;

          const merchantName =
            typeof merchant === "string"
              ? merchant.replace(/'/g, "")
              : "Unknown Merchant";

          const formattedMerchantName =
            merchantName.charAt(0).toUpperCase() +
            merchantName.slice(1).toLowerCase();

          return (
            <div
              key={transactionIndex}
              className={styles.transactionCard}
              onClick={(e) => toggleAdditionalInformation(e, transaction)}
            >
              <div className={styles.transactionDetails}>
                <h4 className={styles.merchantName}>{formattedMerchantName}</h4>
                <p className={styles.transactionDate}>
                  <strong>Date:</strong> {transaction.bookingDate}
                </p>
                <p className={styles.transactionAmount}>
                  <strong>Amount:</strong>{" "}
                  {transaction.transactionAmount?.amount}{" "}
                  {transaction.transactionAmount?.currency}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      {!loading && filteredTransactions?.length < 1 && (
        <h2 className={styles.noTransactionsFound}>No transactions found</h2>
      )}

      {selectedTransaction && (
        <div
          className={styles.modal}
          onClick={(e) => {
            e.stopPropagation();
            closeModal();
          }}
        >
          <div
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <span
              className={styles.close}
              onClick={(e) => {
                e.stopPropagation();
                closeModal();
              }}
            >
              &times;
            </span>
            <h2>Additional Information</h2>
            {selectedTransaction?.additionalInformation ? (
              <div className={styles.additionalInfoContainer}>
                {Object.entries(
                  parseAdditionalInformation(
                    selectedTransaction?.additionalInformation
                  )
                ).map(([key, value], index) => (
                  <div key={index} className={styles.additionalInfoRow}>
                    <span className={styles.additionalInfoKey}>{key}</span>
                    <span className={styles.additionalInfoValue}>{value}</span>
                  </div>
                ))}
              </div>
            ) : (
              <p>No additional information available.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactionTable;
