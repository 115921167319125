import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./ExcelInstructions.module.css";
import click1 from "../images/Click1.png";
import click2 from "../images/Click2.png";
import click3 from "../images/click3.png";
import click4 from "../images/click4.png";
import click5 from "../images/Step 5 - 6.png";
import click7 from "../images/step 7.png";
import click8 from "../images/step 8.png";
import click9 from "../images/step 9.png";
import click10 from "../images/step 10.png";
import clickBalance4 from "../images/load into table step 4.png";
import clickBalance5 from "../images/remove and expand 5.png";
import clickBalance6 from "../images/expand to new rows 6.png";
import clickBalance7 from "../images/expand vaue 7.png";
import clickBalance8 from "../images/rename col 8.png";
import clickBalance9 from "../images/close & load 9.png";

const ExcelInstructions = ({ IBAN, userId }) => {
  const [showBalanceInstructions, setShowBalanceInstructions] = useState(false);
  const [showTransactionInstructions, setShowTransactionInstructions] =
    useState(true);
  const navigate = useNavigate();
  console.log("userId", userId);
  console.log("IBAN", IBAN);

  return (
    <div className={styles.instructionsContainer}>
      <h2>Excel Data Import Instructions</h2>
      <div className={styles.selectionContainer}>
        <button
          className={`${styles.selectionButton} ${
            showTransactionInstructions ? styles.selected : ""
          }`}
          onClick={() => {
            setShowBalanceInstructions(false);
            setShowTransactionInstructions(true);
          }}
        >
          Transaction Instructions
        </button>
        <button
          className={`${styles.selectionButton} ${
            showBalanceInstructions ? styles.selected : ""
          }`}
          onClick={() => {
            setShowTransactionInstructions(false);
            setShowBalanceInstructions(true);
          }}
        >
          Balance Instructions
        </button>
      </div>
      <div className={styles.accountLinks}>
        {IBAN && (
          <div className={styles.accountIdContainer}>
            {showTransactionInstructions && (
              <>
                {IBAN.map((iban) => (
                  <div
                    className={styles.accountId}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `https://kleinmanshloma-open-banking-server.onrender.com/api/v1/transactions/${userId}/${iban}`
                      );
                      alert(`${iban} URL copied to clipboard`);
                    }}
                  >
                    <span>Transactions For This Account {iban}</span>
                    <span className={styles.copyIcon}>📋</span>
                  </div>
                )) || <div>no IBANs</div>}
                <div
                  className={styles.accountId}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://kleinmanshloma-open-banking-server.onrender.com/api/v1/transactions/${userId}`
                    );
                    alert(`URL copied to clipboard`);
                  }}
                >
                  <span>Transactions For All Accounts</span>
                  <span className={styles.copyIcon}>📋</span>
                </div>
              </>
            )}
            {showBalanceInstructions && (
              <>
                <div
                  className={styles.accountId}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://kleinmanshloma-open-banking-server.onrender.com/api/v1/Balances/${userId}`
                    );
                    alert(`URL copied to clipboard`);
                  }}
                >
                  <span>Balances For All Accounts</span>
                  <span className={styles.copyIcon}>📋</span>
                </div>
                {IBAN.length > 0 ? (
                  IBAN.map((iban) => (
                    <div
                      className={styles.accountId}
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `https://kleinmanshloma-open-banking-server.onrender.com/api/v1/Balance/${userId}/${iban}`
                        );
                        alert(`${iban} URL copied to clipboard`);
                      }}
                    >
                      <span>Balance For This Account {iban}</span>
                      <span className={styles.copyIcon}>📋</span>
                    </div>
                  ))
                ) : (
                  <div>no IBANs</div>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <ol className={styles.instructionsList}>
        <li>
          In Excel, go to the "Data" tab and click on "Get Data".
          <img className={styles.imageInstructions} src={click1} alt="click1" />
        </li>
        <li>
          Choose "From Web".
          <img className={styles.imageInstructions} src={click2} alt="click2" />
        </li>
        <li>
          Paste the URL into the box and click "OK".
          <img className={styles.imageInstructions} src={click3} alt="click3" />
        </li>
        <li>
          Rename the query to the account number IBAN or any meaningful name.
          Then click "Into Table".
          <img
            className={styles.imageInstructions}
            src={showBalanceInstructions ? clickBalance4 : click4}
            alt="click4"
          />
        </li>
        <li>
          Remove the first row and expand the second by clicking on the 2
          arrows.
          <img
            className={styles.imageInstructions}
            src={showBalanceInstructions ? clickBalance5 : click5}
            alt="click5"
          />
        </li>
        <li>
          Click on the expand to new rows.
          <img
            className={styles.imageInstructions}
            src={showBalanceInstructions ? clickBalance6 : click7}
            alt="click7"
          />
        </li>
        <p className={styles.importantNote}>
          Note: If you don't see the following image, you can skip this step and
          continue to the next one
        </p>
        <li>
          Click on the expand to new columns.
          <img
            className={styles.imageInstructions}
            src={showBalanceInstructions ? clickBalance7 : click8}
            alt="click8"
          />
        </li>
        <li>
          Uncheck the _id and uncheck "Use original column name as prefix". Then
          click "OK".
          <img
            className={styles.imageInstructions}
            src={showBalanceInstructions ? clickBalance8 : click9}
            alt="click9"
          />
        </li>
        <li>
          Click "Close & Load" to finish.
          <img
            className={styles.imageInstructions}
            src={showBalanceInstructions ? clickBalance9 : click10}
            alt="click10"
          />
        </li>
        <li>
          You are all done. Now you can update with just clicking "Refresh" in
          the Data tab.
        </li>
      </ol>
    </div>
  );
};

export default ExcelInstructions;
