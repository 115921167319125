import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./BankList.css";
import config from "../../config/config";

const ConnectToBank = ({ userData }) => {
  const navigate = useNavigate();

  // if no token, redirect to login
  if (!localStorage.getItem("token")) {
    navigate("/Login");
  }

  const [banks, setBanks] = useState([]);
  const [filteredBanks, setFilteredBanks] = useState([]);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [countryCode, setCountryCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchBanks = async () => {
      const results = await fetch(
        `${config.URL_PROD}/api/v1/bank/allBelgiumBanks/${countryCode}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await results.json();
      console.log("Banks data:", data);

      if (
        (data.message && data.message?.startsWith("Unauthorized")) ||
        data.message?.startsWith("Failed")
      ) {
        setErrorMessage(data.message);
      }
      if (!Array.isArray(data)) {
        setBanks([]);
        setFilteredBanks([]);
      } else {
        setBanks(data);
        setFilteredBanks(data);
      }
    };
    fetchBanks();
  }, [countryCode]);

  const handleSelectBank = (bankId) => {
    setSelectedBankId(bankId);

    const connectToBank = async () => {
      const results = await fetch(
        `${config.URL_PROD}/api/v1/bank/requisition`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            redirect: `${config.URL_PROD}/api/v1/bank/getAccountsAndSaveDatabase`,
            institution_id: bankId,
            username: userData.username,
          }),
        }
      );

      if (!results.ok) {
        throw new Error(`HTTP error! status: ${results.status}`);
      }

      const data = await results.json();
      const link = data.link;

      window.open(link, "_self");
    };
    connectToBank();
  };

  const searchBank = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filteredBanks = banks.filter((bank) =>
      bank.name.toLowerCase().includes(searchTerm)
    );

    const sortedBanks = filteredBanks.sort((a, b) =>
      a.name.toLowerCase().startsWith(searchTerm) ? -1 : 1
    );

    setFilteredBanks(sortedBanks);

    if (searchTerm === "") {
      const sortedBanks = banks.sort((a, b) => a.name.localeCompare(b.name));
      setFilteredBanks(sortedBanks);
    }
  };

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage("");
        navigate("/Login");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  if (errorMessage) {
    return (
      <div className="errorMessage">
        <button
          className="errorCloseBtn"
          onClick={() => {
            setErrorMessage("");
            navigate("/Login");
          }}
        >
          X
        </button>
        <p>{errorMessage}</p>
      </div>
    );
  }

  return (
    <div className="bank-list-page">
      <h1 className="pageName">Connect to Bank</h1>
      <div className="mainBox">
        <p>Select your country</p>
        <select
          className="country-select"
          name="country"
          id="country"
          onChange={(e) => {
            setCountryCode(e.target.value);
          }}
        >
          <option value="">Select your country</option>
          <option value="be">Belgium</option>
          <option value="gb">United Kingdom</option>
          <option value="nl">Netherlands</option>
          <option value="at">Austria</option>
          <option value="cy">Cyprus</option>
          <option value="de">Germany</option>
        </select>
        {countryCode && (
          <>
            <div className="searchBank">
              <div className="searchBank-Title">Search your bank</div>
              <input
                type="text"
                placeholder="Search your bank"
                className="searchBank-searchBox"
                onChange={searchBank}
                autoFocus
              />
            </div>
            <form className="bank-list">
              {Array.isArray(filteredBanks) && filteredBanks.length > 0
                ? filteredBanks.map((bank) => (
                    <div key={bank.id} className="bank-item">
                      <label htmlFor={bank.id}>
                        <div className="bank-details">
                          <input
                            type="radio"
                            id={bank.id}
                            name="bank"
                            value={bank.id}
                            checked={selectedBankId === bank.id}
                            onChange={() => handleSelectBank(bank.id)}
                          />
                          <div className="bank-name-bic">
                            <div className="bank-name">{bank.name}</div>
                            <div className="bank-bic">{bank.bic}</div>
                          </div>
                          <div className="bank-logo-box">
                            <div className="bank-logo">
                              <img src={bank.logo} alt={bank.name} />
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                  ))
                : null}
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default ConnectToBank;
