import React from "react";
import "./TermsAndPrivacy.css";

const emailAddress = require("../../config/config").emailAddress;
const phoneNumber = require("../../config/config").phoneNumber;

const TermsAndPrivacyUpdatedDate = "11/02/2025";

const TermsAndPrivacy = () => {
  return (
    <div className="terms-container">
      <h1>Terms and Conditions & Privacy Policy</h1>
      <p>
        Last Updated: <span>{TermsAndPrivacyUpdatedDate}</span>
      </p>

      <h2>1. Introduction</h2>
      <p>
        1.1 These Terms and Conditions ("Agreement") govern the collection, use,
        and storage of personal data provided by users ("Users") through
        BankAccountsInExcel ("we," "our," or "the Company"). By using our
        services, Users agree to comply with the terms outlined herein. If you
        do not agree with these terms, please do not use our services.
      </p>
      <p>
        1.2 BankAccountsInExcel is committed to respecting your privacy and
        protecting your personal data. This Agreement provides details on how we
        collect, process, store, and secure your data.
      </p>

      <h2>2. Data Collection</h2>
      <h3>2.1 Types of Data Collected</h3>
      <p>We collect personal data, including but not limited to:</p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Bank account information (if provided)</li>
        <li>Payment details (for transactions, balances)</li>
        <li>IP address and device information</li>
      </ul>

      <h3>2.2 Purpose of Data Collection</h3>
      <p>The data is collected for the following purposes:</p>
      <ul>
        <li>Providing and improving our services</li>
        <li>Processing transactions and payments</li>
        <li>
          Communicating with Users about updates, support, or promotional
          content
        </li>
        <li>Compliance with legal obligations</li>
        <li>Analyzing service usage for performance and improvement</li>
      </ul>

      <h3>2.3 Consent to Collection</h3>
      <p>
        By submitting your personal data to us, you consent to the collection
        and processing of this data for the purposes specified above.
      </p>

      <h2>3. Data Storage and Protection</h2>
      <h3>3.1 Data Security</h3>
      <p>
        We implement reasonable technical and organizational measures to protect
        your personal data from unauthorized access, alteration, disclosure, or
        destruction. These include encryption, secure servers, and regular
        security audits.
      </p>

      <h3>3.2 Data Retention</h3>
      <p>
        We will retain your personal data for as long as necessary to fulfill
        the purposes outlined in this Agreement, or for the duration required by
        applicable law (e.g., tax or legal retention periods). Once data is no
        longer needed, we will securely delete it.
      </p>

      <h3>3.3 No Absolute Security Guarantee</h3>
      <p>
        While we strive to protect your personal data, no data transmission over
        the internet or electronic storage system is 100% secure. We cannot
        guarantee the absolute security of your data.
      </p>

      <h2>4. Limitation of Liability</h2>
      <h3>4.1 General Limitation of Liability</h3>
      <p>
        By using our services, you agree that BankAccountsInExcel will not be
        held liable for any direct, indirect, incidental, special,
        consequential, or punitive damages, including loss of data, arising from
        the collection, storage, or use of your personal data.
      </p>

      <h3>4.2 Third-Party Liability</h3>
      <p>
        We are not responsible for the actions or security measures of
        third-party services, including payment processors, that may be involved
        in processing your personal data. We recommend reviewing their privacy
        policies for more information.
      </p>

      <h3>4.3 Security Risks</h3>
      <p>
        You understand that data transmission over the internet, including
        personal data, is inherently insecure and subject to potential risks.
        While we take measures to protect your data, we cannot guarantee that
        unauthorized access or misuse will not occur.
      </p>

      <h2>5. User Rights</h2>
      <h3>5.1 Your Rights under GDPR</h3>
      <p>
        In accordance with the General Data Protection Regulation (GDPR), you
        have the following rights regarding your personal data:
      </p>
      <ul>
        <li>
          Right to Access: You have the right to request access to your personal
          data.
        </li>
        <li>
          Right to Rectification: You can request that we correct inaccurate or
          incomplete data.
        </li>
        <li>
          Right to Erasure: You can request the deletion of your data, subject
          to applicable legal obligations.
        </li>
        <li>
          Right to Restrict Processing: You can request that we restrict the
          processing of your data under certain circumstances.
        </li>
        <li>
          Right to Data Portability: You may request a copy of your data in a
          commonly used, machine-readable format.
        </li>
        <li>
          Right to Object: You can object to the processing of your data for
          certain purposes, such as direct marketing.
        </li>
      </ul>

      <h3>5.2 Exercising Your Rights</h3>
      <p>
        To exercise any of the above rights, please contact us at{" "}
        <span>{emailAddress}</span>. We will respond to your request within the
        time frame required by law.
      </p>

      <h3>5.3 Withdrawal of Consent</h3>
      <p>
        You can withdraw your consent to the processing of your data at any
        time. Please note that withdrawing consent may limit your ability to use
        certain features of our services.
      </p>

      <h2>6. Compliance with Laws</h2>
      <h3>6.1 General Data Protection Regulation (GDPR)</h3>
      <p>
        BankAccountsInExcel is committed to complying with the General Data
        Protection Regulation (GDPR) and applicable Belgian laws concerning data
        protection.
      </p>

      <h3>6.2 Data Transfers</h3>
      <p>
        If your personal data is transferred to countries outside of the
        European Economic Area (EEA), we will ensure that appropriate safeguards
        are in place to protect your data, in compliance with the GDPR.
      </p>

      <h3>6.3 Legal Jurisdiction</h3>
      <p>
        This Agreement will be governed by and construed in accordance with the
        laws of Belgium. Any dispute arising from these Terms and Conditions
        will be subject to the exclusive jurisdiction of the courts located in
        Belgium.
      </p>

      <h2>7. Amendments to Terms</h2>
      <h3>7.1 Changes to Terms</h3>
      <p>
        We reserve the right to update or amend these Terms and Conditions at
        any time. Any changes will be posted on our website, and the effective
        date will be updated accordingly.
      </p>

      <h3>7.2 User Notification</h3>
      <p>
        We will notify Users of any material changes to these Terms and
        Conditions by posting a notice on our website or by sending a
        notification through our services. Continued use of our services after
        such changes will constitute your acceptance of the updated Terms and
        Conditions.
      </p>

      <h2>8. Contact Information</h2>
      <p>
        If you have any questions, concerns, or requests regarding these Terms
        and Conditions or your personal data, please contact us at:
      </p>
      <p>
        BankAccountsInExcel
        <br />
        Email: <span>{emailAddress}</span>
        <br />
        Address: <span className="red-text">[Contact Address]</span>
        <br />
        Phone: <span>{phoneNumber}</span>
      </p>

      <h2>9. Privacy Policy</h2>
      <p>
        This Privacy Policy explains how we collect, use, disclose, and protect
        your information when you visit our website.
      </p>

      <h3>9.1 Information We Collect</h3>
      <p>
        We may collect information about you in a variety of ways. The
        information we may collect on the Site includes:
      </p>
      <ul>
        <li>
          Personal Data: Personally identifiable information, such as your name,
          shipping address, email address, and telephone number, and demographic
          information, such as your age, gender, hometown, and interests, that
          you voluntarily give to us when you register with the Site or when you
          choose to participate in various activities related to the Site, such
          as online chat and message boards.
        </li>
        <li>
          Derivative Data: Information our servers automatically collect when
          you access the Site, such as your IP address, your browser type, your
          operating system, your access times, and the pages you have viewed
          directly before and after accessing the Site.
        </li>
        <li>
          Financial Data: Financial information, such as data related to your
          payment method (e.g., valid credit card number, card brand, expiration
          date) that we may collect when you purchase, order, return, exchange,
          or request information about our services from the Site.
        </li>
      </ul>

      <h3>9.2 Use of Your Information</h3>
      <p>
        Having accurate information about you permits us to provide you with a
        smooth, efficient, and customized experience. Specifically, we may use
        information collected about you via the Site to:
      </p>
      <ul>
        <li>Create and manage your account.</li>
        <li>
          Process your transactions and send you related information, including
          purchase confirmations and invoices.
        </li>
        <li>
          Send you technical notices, updates, security alerts, and support and
          administrative messages.
        </li>
        <li>
          Respond to your comments, questions, and requests and provide customer
          service.
        </li>
        <li>
          Communicate with you about products, services, offers, promotions,
          rewards, and events offered by us and others, and provide news and
          information we think will be of interest to you.
        </li>
        <li>
          Monitor and analyze trends, usage, and activities in connection with
          our Services.
        </li>
        <li>
          Personalize and improve the Services and provide advertisements,
          content, or features that match user profiles or interests.
        </li>
      </ul>

      <h3>9.3 Disclosure of Your Information</h3>
      <p>
        We may share information we have collected about you in certain
        situations. Your information may be disclosed as follows:
      </p>
      <ul>
        <li>
          By Law or to Protect Rights: If we believe the release of information
          about you is necessary to respond to legal process, to investigate or
          remedy potential violations of our policies, or to protect the rights,
          property, and safety of others, we may share your information as
          permitted or required by any applicable law, rule, or regulation.
        </li>
        <li>
          Third-Party Service Providers: We may share your information with
          third parties that perform services for us or on our behalf, including
          payment processing, data analysis, email delivery, hosting services,
          customer service, and marketing assistance.
        </li>
        <li>
          Business Transfers: We may share or transfer your information in
          connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our
          business to another company.
        </li>
      </ul>

      <h3>9.4 Security of Your Information</h3>
      <p>
        We use administrative, technical, and physical security measures to help
        protect your personal information. While we have taken reasonable steps
        to secure the personal information you provide to us, please be aware
        that despite our efforts, no security measures are perfect or
        impenetrable, and no method of data transmission can be guaranteed
        against any interception or other type of misuse.
      </p>

      <h3>9.5 Policy for Children</h3>
      <p>
        We do not knowingly solicit information from or market to children under
        the age of 13. If we become aware that we have collected personal
        information from a child under age 13 without verification of parental
        consent, we will delete that information as quickly as possible. If you
        believe we might have any information from or about a child under 13,
        please contact us at <span>{emailAddress}</span>.
      </p>

      <h3>9.6 Changes to This Privacy Policy</h3>
      <p>
        We may update this Privacy Policy from time to time in order to reflect,
        for example, changes to our practices or for other operational, legal,
        or regulatory reasons.
      </p>

      <h3>9.7 Contact Us</h3>
      <p>
        If you have questions or comments about this Privacy Policy, please
        contact us at:
      </p>
      <p>
        BankAccountsInExcel
        <br />
        Email: <span>{emailAddress}</span>
        <br />
        Address: <span className="red-text">[Contact Address]</span>
        <br />
        Phone: <span>{phoneNumber}</span>
      </p>
    </div>
  );
};

export default TermsAndPrivacy;
