import React, { useState, useEffect } from "react";
import "./Modal.css";

const Modal = ({
  isOpen,
  onClose,
  type = "info", // 'success', 'error', 'confirm', or 'info'
  title,
  message,
  onConfirm,
  onCancel,
  confirmText = "Delete Account",
  cancelText = "Cancel Back to Accounts",
  showTimer = false,
  onTimerComplete,
  duration = 5000, // default 5 seconds
  animationType = "slide", // or "fade" or other animation types
}) => {
  const [progress, setProgress] = useState(0);
  const [hasUserActed, setHasUserActed] = useState(false);
  const [shouldClose, setShouldClose] = useState(false);

  useEffect(() => {
    let timer;
    if (showTimer && isOpen) {
      const startTime = Date.now();

      const updateProgress = () => {
        const elapsed = Date.now() - startTime;
        const newProgress = (elapsed / duration) * 100;

        if (newProgress >= 100) {
          setProgress(100);
          if (!hasUserActed) {
            setShouldClose(true);
            onTimerComplete && onTimerComplete();
            onClose && onClose();
          }
          clearInterval(timer);
        } else {
          setProgress(newProgress);
        }
      };

      timer = setInterval(updateProgress, 10);
      return () => clearInterval(timer);
    }
  }, [showTimer, isOpen, onTimerComplete, hasUserActed, duration, onClose]);

  useEffect(() => {
    if (shouldClose) {
      onClose && onClose();
      setShouldClose(false);
    }
  }, [shouldClose, onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className={`modal ${type} ${animationType}`}
        onClick={(e) => e.stopPropagation()}
      >
        {showTimer && (
          <div className="timer-bar" style={{ width: `${progress}%` }} />
        )}
        <div className="modal-content">
          <h2>{title}</h2>
          <p>{message}</p>
          <div className="modal-buttons">
            {type === "confirm" ? (
              <>
                <button className="delete-btn" onClick={onConfirm}>
                  {confirmText}
                </button>
                <button className="back-btn" onClick={onCancel}>
                  {cancelText}
                </button>
              </>
            ) : (
              <button
                className="back-btn"
                onClick={() => {
                  setHasUserActed(true);
                  onCancel && onCancel();
                }}
              >
                {cancelText}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
