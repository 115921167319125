const config = {
  URL_DEV: "http://localhost:8000",
  URL_PROD: "https://kleinmanshloma-open-banking-server.onrender.com",
};

export const emailAddress = "shloma@perfectbusinessoverview.com";
export const address = "";
export const phoneNumber = "+13479155773";

export default config;
