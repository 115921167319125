import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./viewSettingsPage.module.css";

const BASE_URL_DEV = "http://localhost:8080/api/v1";
const BASE_URL_PROD =
  "https://kleinmanshloma-open-banking-server.onrender.com/api/v1";

const ViewSettingsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [initialSettings, setInitialSettings] = useState({});
  const previousPage = location.state?.from || "/";
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editableSettings, setEditableSettings] = useState(initialSettings);
  console.log(editableSettings);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetch(`${BASE_URL_PROD}/settings`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch settings");
        }

        const settingsResponse = await response.json();
        console.log(settingsResponse.settings);
        setInitialSettings(settingsResponse.settings);
        setEditableSettings(settingsResponse.settings);
        setLoading(false);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchSettings();
  }, []);

  const orderedSettingsKeys = [
    ...Object.keys(initialSettings.emailSettings || {}),
    ...Object.keys(initialSettings).filter(
      (key) => !["__v", "_id", "User", "emailSettings"].includes(key)
    ),
  ];

  const handleInputChange = (event, key) => {
    const { type, checked, value } = event.target;
    const newValue = type === "checkbox" ? checked : value;

    if (key in editableSettings.emailSettings) {
      setEditableSettings((prevState) => ({
        ...prevState,
        emailSettings: {
          ...prevState.emailSettings,
          [key]: newValue,
        },
      }));
    } else {
      setEditableSettings((prevState) => ({
        ...prevState,
        [key]: newValue,
      }));
    }
  };

  const handleSave = async () => {
    const updatedSettings = {
      emailSettings: editableSettings.emailSettings,
      ...Object.keys(editableSettings).reduce((acc, key) => {
        if (key !== "emailSettings") {
          acc[key] = editableSettings[key];
        }
        return acc;
      }, {}),
    };

    try {
      const response = await fetch(`${BASE_URL_PROD}/settings/update`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(updatedSettings),
      });

      if (!response.ok) {
        throw new Error("Failed to update settings");
      }

      const settingsResponse = await response.json();
      const updatedSettingsResponse = settingsResponse.message;
      console.log(settingsResponse);

      console.log(updatedSettingsResponse?.message || updatedSettingsResponse);

      setNotification(updatedSettingsResponse);
      setTimeout(() => {
        setNotification(null);
        navigate(previousPage);
      }, 2000);
    } catch (error) {
      console.error(error.message);
    }
  };

  if (!editableSettings.emailSettings) {
    return <div>Loading settings...</div>;
  }

  const splitCamelCaseString = (camelCaseString) => {
    const splitString = camelCaseString.replace(/([a-z])([A-Z])/g, "$1 $2");
    return splitString.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <div className={styles.settingsPageContainer}>
      <h2>Settings</h2>
      <div className={styles.settingsListContainer}>
        <ul className={styles.settingsList}>
          {orderedSettingsKeys.map((key) => (
            <li key={key} className={styles.settingsItem}>
              <label htmlFor={key}>{splitCamelCaseString(key)}:</label>
              {typeof editableSettings.emailSettings[key] === "boolean" ||
              typeof editableSettings[key] === "boolean" ? (
                <label className={styles.switch}>
                  <input
                    id={key}
                    type="checkbox"
                    checked={
                      editableSettings.emailSettings[key] !== undefined
                        ? editableSettings.emailSettings[key]
                        : editableSettings[key]
                    }
                    onChange={(event) => handleInputChange(event, key)}
                  />
                  <span className={styles.slider}></span>
                </label>
              ) : (
                <input
                  id={key}
                  type={key === "sendOnlyTrasactionsAbove" ? "number" : "text"}
                  value={
                    editableSettings.emailSettings[key] !== undefined
                      ? editableSettings.emailSettings[key]
                      : editableSettings[key]
                  }
                  onChange={(event) => handleInputChange(event, key)}
                  className={styles.inputField}
                />
              )}
            </li>
          ))}
        </ul>
        <button onClick={handleSave} className={styles.saveButton}>
          Save
        </button>
      </div>
      {notification && (
        <div className={styles.notification}>{notification}</div>
      )}
    </div>
  );
};

export default ViewSettingsPage;
