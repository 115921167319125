import React from "react";
import "./LandingPage.css";

const LandingPage = () => {
  return (
    <div>
      <div className="hero-content">
        <div className="hero">
          <header className="header-center">
            <h1 className="header-text">
              Simplify Your Finances with BankAccountsInExcel.com
            </h1>
          </header>
          <p>
            Tired of struggling with messy spreadsheets and manual transaction
            tracking? <br />
            Automate, organize, and take control of your bank accounts today!
          </p>
          <a href="https://bankaccountsinexcel.com/signup" className="cta">
            Simplify Now
          </a>
        </div>
      </div>

      <div className="features" id="features">
        <div className="feature">
          <h3>🚀 Automated Bank Feeds</h3>
          <p>
            Say goodbye to manual data entry! Our system syncs with your bank
            and keeps your records up to date. Twice a day, every day.
          </p>
        </div>
        <div className="feature">
          <h3>🌍 Works Anywhere</h3>
          <p>
            Whether you love Excel, Google Sheets, or our online dashboard –
            we’ve got you covered.
          </p>
        </div>
        <div className="feature">
          <h3>📧 Instant Email Alerts</h3>
          <p>
            Get notified of new transactions twice a day. Enjoy peace of mind
            knowing your finances are always up to date!
          </p>
        </div>
      </div>

      <div className="pricing" id="pricing">
        <div className="pricing-content">
          <h2>💰 Transparent Pricing</h2>
          <p>
            No hidden fees.
            <br /> No surprises.
          </p>

          <p>
            Our pricing is based on the number of Accounts, with each account
            including linked accounts as one account.
          </p>

          <div className="pricing-table">
            <div className="pricing-row">
              <span className="pricing-feature">1 Account</span>
              <span className="pricing-cost" style={{ color: "#ffd700" }}>
                €12/month
              </span>
            </div>
            <div className="pricing-row">
              <span className="pricing-feature">2 Accounts</span>
              <span className="pricing-cost" style={{ color: "#ffd700" }}>
                €20/month
              </span>
            </div>
            <div className="pricing-row">
              <span className="pricing-feature">3 Accounts</span>
              <span className="pricing-cost" style={{ color: "#ffd700" }}>
                €25/month
              </span>
            </div>
            <div className="pricing-row">
              <span className="pricing-feature">Each Additional Account</span>
              <span className="pricing-cost" style={{ color: "#ffd700" }}>
                + €5/month
              </span>
            </div>
          </div>
          <a href="https://bankaccountsinexcel.com/signup" className="cta">
            Simplify Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
