import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  homeLinkSvg,
  backToAccountsSvg,
  logoutSvg,
  profileSvg,
  settingsSvg,
  addAccountSvg,
  deleteAccountSvg,
} from "../assets/svgs"; // Import SVGs from new file
import logo from "../assets/bankingLogo.png";
import "./NavBar.css";
import ExportToCoda from "../ExportToCoda/ExportToCoda";
import Modal from "../common/Modal/Modal"; // Import the Modal component

const NavBar = ({
  showCopyUrlSection,
  showConnectionLimits,
  accountsID,
  setShowProfileMenu,
  showProfileMenu,
  setExpandUrls,
  expandUrls,
  MayGetExtraUrlForEachAccountNumberForExcel,
  setMayGetExtraUrlForEachAccountNumberForExcel,
  setRequisitionLength,
  setAmountOfRequisitions,
  setIsRequisitionLimitReached,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const profileMenuRef = useRef(null);
  const profileButtonRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleHomeClick = (e) => {
    e.preventDefault();
    localStorage.clear();
    sessionStorage.clear();

    if (location.pathname === "/") {
      if (window.scrollY === 0) {
        setIsMobileMenuOpen(false);
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        setIsMobileMenuOpen(false);
      }
    } else {
      setIsMobileMenuOpen(false);
      navigate("/");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  // Close mobile menu when route changes
  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location.pathname]);

  // Close mobile menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isMobileMenuOpen &&
        !event.target.closest(".nav-links") &&
        !event.target.closest(".mobile-menu-toggle")
      ) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isMobileMenuOpen]);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handle clicks outside of profile menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target) &&
        !profileButtonRef.current.contains(event.target)
      ) {
        setShowProfileMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowProfileMenu]);

  // Close profile menu when mobile menu is toggled
  useEffect(() => {
    if (isMobileMenuOpen) {
      setShowProfileMenu(false);
    }
  }, [isMobileMenuOpen, setShowProfileMenu]);

  // Close profile menu when route changes
  useEffect(() => {
    setShowProfileMenu(false);
  }, [location.pathname, setShowProfileMenu]);

  const amountOfRequisitions = localStorage.getItem("amountOfRequisitions");
  const requisitionLength = localStorage.getItem("Requisition Length");
  const isRequisitionLimitReached = amountOfRequisitions === requisitionLength;

  useEffect(() => {
    const storedRequisitionLength = localStorage.getItem("Requisition Length");
    const storedAmountOfRequisitions = localStorage.getItem(
      "amountOfRequisitions"
    );
    const storedMayGetExtraUrlForEachAccountNumberForExcel =
      localStorage.getItem("getExtraUrlForEachAccountNumberForExcel") ===
      "true";

    setRequisitionLength(storedRequisitionLength);
    setAmountOfRequisitions(storedAmountOfRequisitions);
    setIsRequisitionLimitReached(
      storedAmountOfRequisitions >= storedRequisitionLength
    );
    setMayGetExtraUrlForEachAccountNumberForExcel(
      storedMayGetExtraUrlForEachAccountNumberForExcel
    );
  }, [
    setRequisitionLength,
    setAmountOfRequisitions,
    setIsRequisitionLimitReached,
    setMayGetExtraUrlForEachAccountNumberForExcel,
  ]);

  const handleLogout = () => {
    // Clear the local storage and session storage
    localStorage.clear();
    sessionStorage.clear();

    // Reset state variables
    setShowProfileMenu(false);
    setExpandUrls(false);
    setMayGetExtraUrlForEachAccountNumberForExcel(false);
    setRequisitionLength(null);
    setAmountOfRequisitions(null);
    setIsRequisitionLimitReached(false);

    // Redirect to login page
    navigate("/Login");
  };

  const handleScroll = (e, targetId) => {
    e.preventDefault();
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const offset = targetId === "pricing" ? 30 : 110;
      const elementPosition = targetElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
      setIsMobileMenuOpen(false); // Close menu after clicking
    }
  };

  const handleNavClick = (e, path) => {
    e.preventDefault();
    setIsMobileMenuOpen(false); // This will trigger the fly-up animation

    // Wait for the animation to complete before navigating
    setTimeout(() => {
      if (path === "/") {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
      navigate(path);
    }, 400); // Match this with the CSS transition duration
  };

  const toggleProfileMenu = (e) => {
    e.stopPropagation();
    setShowProfileMenu(!showProfileMenu);
  };

  const fetchLatestData = async () => {
    try {
      const userId = localStorage.getItem("userId");

      const currentTime = new Date();

      // Define update windows
      const updateWindowStart1 = new Date(currentTime);
      updateWindowStart1.setHours(10, 0, 0, 0); // 10:00 AM
      const updateWindowEnd1 = new Date(currentTime);
      updateWindowEnd1.setHours(11, 0, 0, 0); // 11:00 AM

      const updateWindowStart2 = new Date(currentTime);
      updateWindowStart2.setHours(16, 0, 0, 0); // 4:00 PM
      const updateWindowEnd2 = new Date(currentTime);
      updateWindowEnd2.setHours(17, 0, 0, 0); // 5:00 PM

      // Determine the last fetch time
      let lastFetchTime;
      if (currentTime.getHours() < 10) {
        lastFetchTime = "4:00 PM (previous day)";
      } else if (currentTime.getHours() < 16) {
        lastFetchTime = "10:00 AM";
      } else {
        lastFetchTime = "4:00 PM";
      }

      // Ask the user if they want to fetch the data based on conditions
      const userResponse = window.confirm(
        `The system is updated at 10:00 AM and 4:00 PM. 
  Last fetch was at ${lastFetchTime}. 
  It's currently ${currentTime.toLocaleString()}. 
  Do you want to fetch the data now?`
      );

      if (!userResponse) {
        return;
      }

      // Proceed with fetching data if the user confirms
      const response = await fetch(
        `https://kleinmanshloma-open-banking-server.onrender.com/api/v1/user/fetchLatestData/${userId}`
      );

      // how can i now that  it is sent no 404 but panding for a result?
      if (response.status === 404) {
        alert("Error: Failed to fetch latest data. Please try again later.");
        console.error("Error fetching latest data:", response);
        return;
      } else if (response.status === 200) {
        alert("Data fetched successfully, please wait for page to reload");
        navigate("/BankAccountsData");
        return;
      }

      if (response.ok) {
        const data = await response.json();
        if (data.message) {
          alert(`Only once a day for manual refresh:
            : ${data.message}`);
        }
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      alert("Error: Failed to fetch latest data. Please try again later.");
      console.error("Error fetching latest data:", error);
    }
  };

  // Show only logo and home link on login and signup pages
  if (
    location.pathname.toLowerCase() === "/login" ||
    location.pathname.toLowerCase() === "/signup"
  ) {
    return (
      <nav className="navbar">
        <div className="nav-logo-container">
          <Link to="/" onClick={handleHomeClick}>
            <img src={logo} alt="Logo" className="nav-logo" />
          </Link>
        </div>
        <div className="nav-items-container">
          <div className={`nav-links ${isMobileMenuOpen ? "open" : ""}`}>
            <Link
              to="/"
              className="nav-link home-link"
              onClick={handleHomeClick}
            >
              {homeLinkSvg}
              Home
            </Link>
          </div>
        </div>
      </nav>
    );
  }

  if (
    location.pathname.toLowerCase() === "/connecttobank" ||
    location.pathname.toLowerCase() === "/demo-dashboard"
  ) {
    if (
      localStorage.getItem("Requisition") > 0 ||
      sessionStorage.getItem("Requisition") > 0
    ) {
      return (
        <nav className="navbar">
          <div className="nav-logo-container">
            <Link to="#">
              <img src={logo} alt="Logo" className="nav-logo" />
            </Link>
            <Link to="/BankAccountsData" className="nav-link">
              {backToAccountsSvg}
              Back to Accounts
            </Link>
          </div>
          <div className="nav-items-container">
            <div className={`nav-links ${isMobileMenuOpen ? "open" : ""}`}>
              {/* Remove the duplicate logo link */}
            </div>
          </div>
        </nav>
      );
    } else {
      return (
        <nav className="navbar">
          <div className="nav-logo-container">
            <Link to="#">
              <img src={logo} alt="Logo" className="nav-logo" />
            </Link>
            <Link
              className="nav-link"
              onClick={() => {
                window.history.back();
                // clear the local storage and session storage
                localStorage.clear();
                sessionStorage.clear();
              }}
            >
              {backToAccountsSvg}
              Back
            </Link>
          </div>
          <div className="nav-items-container">
            <div className={`nav-links ${isMobileMenuOpen ? "open" : ""}`}>
              {/* Remove the duplicate logo link */}
            </div>
          </div>
        </nav>
      );
    }
  }

  if (
    location.pathname.toLowerCase() === "/terms-and-privacy" ||
    location.pathname.toLowerCase() === "/contact"
  ) {
    return (
      <nav className="navbar">
        <div className="nav-logo-container">
          <Link to="#">
            <img src={logo} alt="Logo" className="nav-logo" />
          </Link>
          {(localStorage.getItem("token") !== null &&
            (localStorage.getItem("Requisition") > 0 ||
              sessionStorage.getItem("Requisition") > 0)) ||
          (sessionStorage.getItem("email") !== null &&
            (localStorage.getItem("Requisition") > 0 ||
              sessionStorage.getItem("Requisition") > 0)) ? (
            <Link to="/BankAccountsData" className="nav-link">
              {backToAccountsSvg}
              Back to Accounts
            </Link>
          ) : (
            //  a link to go to previous page
            <Link className="nav-link" onClick={() => window.history.back()}>
              {backToAccountsSvg}
              Back
            </Link>
          )}
        </div>
        <div className="nav-items-container">
          <div className={`nav-links ${isMobileMenuOpen ? "open" : ""}`}>
            {/* Remove the duplicate logo link */}
          </div>
        </div>
      </nav>
    );
  }

  if (location.pathname.toLowerCase() === "/login-demo") {
    return (
      <nav className="navbar">
        <div className="nav-logo-container">
          <Link to="/">
            <img src={logo} alt="Logo" className="nav-logo" />
          </Link>
        </div>
        <div className="nav-items-container">
          <div className={`nav-links ${isMobileMenuOpen ? "open" : ""}`}>
            <Link to="/" className="nav-link" onClick={handleHomeClick}>
              {homeLinkSvg}
              Home
            </Link>
          </div>
        </div>
      </nav>
    );
  }

  // Default navbar for landing page
  if (location.pathname === "/") {
    return (
      <nav className="navbar">
        <div
          className="nav-logo-container"
          style={{ display: isMobileMenuOpen ? "none" : "block" }}
        >
          <Link to="/" onClick={handleHomeClick}>
            <img src={logo} alt="Logo" className="nav-logo" />
          </Link>
        </div>
        <div className="nav-items-container">
          <div className={`nav-links ${isMobileMenuOpen ? "open" : ""}`}>
            <div className="mobile-nav-header mobile-only">
              <Link to="/" onClick={handleHomeClick}>
                <img src={logo} alt="Logo" className="nav-logo" />
              </Link>
            </div>
            <Link to="/" className="nav-link" onClick={handleHomeClick}>
              {homeLinkSvg}
              Home
            </Link>

            {/* add a see demo button */}
            <Link to="/login-demo" className="nav-link">
              Demo
            </Link>

            <a
              href="#features"
              className="nav-link"
              onClick={(e) => handleScroll(e, "features")}
            >
              Features
            </a>
            <a
              href="#pricing"
              className="nav-link"
              onClick={(e) => handleScroll(e, "pricing")}
            >
              Pricing
            </a>
            <Link to="/Signup" className="nav-link">
              Sign Up
            </Link>
            <Link to="/Login" className="nav-link">
              Login
            </Link>
          </div>
          <button
            className="mobile-menu-toggle"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            ☰
          </button>
        </div>
      </nav>
    );
  }

  const isDemo = localStorage.getItem("userId") === "demo";

  // Special navbar for BankAccountsData page
  if (location.pathname === "/BankAccountsData") {
    return (
      <nav className="navbar">
        <div
          className="nav-logo-container"
          style={{ display: isMobileMenuOpen ? "none" : "block" }}
        >
          <Link to="#">
            <img src={logo} alt="Logo" className="nav-logo" />
          </Link>
        </div>
        <div className="nav-items-container">
          <div className={`nav-links ${isMobileMenuOpen ? "open" : ""}`}>
            <div className="mobile-nav-header mobile-only">
              <Link to="#">
                <img src={logo} alt="Logo" className="nav-logo" />
              </Link>
            </div>
            <div className="nav-center">
              {showConnectionLimits && (
                <div className="connection-limits">
                  <span>
                    Connections: {amountOfRequisitions}/{requisitionLength}
                  </span>
                  {!isRequisitionLimitReached && (
                    <>
                      <button
                        title={isDemo ? "Please sign up first" : ""}
                        disabled={isDemo}
                        onClick={() => navigate("/ConnectToBank")}
                        className="add-account-btn"
                      >
                        {addAccountSvg}
                        Add Account
                      </button>
                    </>
                  )}
                </div>
              )}
              {showCopyUrlSection && (
                <>
                  <button
                    onClick={fetchLatestData}
                    className="instructions-btn"
                    style={{ marginRight: "10px" }}
                  >
                    🔃 Refresh Data
                  </button>
                  <button
                    onClick={() =>
                      navigate("/excel-instructions", {
                        state: {
                          accountIds: accountsID,
                          userId: localStorage.getItem("userId"),
                          accounts: JSON.parse(
                            localStorage.getItem("accounts")
                          ),
                        },
                      })
                    }
                    className="instructions-btn"
                  >
                    Excel Instructions
                  </button>
                </>
              )}
            </div>
            {/* Add ExportToCoda component here */}
            <div className="nav-link">
              <button
                className="export-file"
                onClick={() => setIsModalOpen(true)}
              >
                Export to ...
              </button>
            </div>
            <div className="nav-right">
              <button
                ref={profileButtonRef}
                className="profile-btn"
                onClick={toggleProfileMenu}
              >
                {profileSvg}
                Profile
              </button>
              {showProfileMenu && (
                <div ref={profileMenuRef} className="profile-menu">
                  <Link
                    to="/ViewSettingsPage"
                    className="profile-menu-item"
                    onClick={() => setShowProfileMenu(false)}
                  >
                    {settingsSvg}
                    Settings
                  </Link>
                  <Link
                    to="/delete-account"
                    className="profile-menu-item"
                    onClick={() => setShowProfileMenu(false)}
                  >
                    {deleteAccountSvg}
                    Delete Account
                  </Link>
                  <button
                    onClick={() => {
                      setShowProfileMenu(false);
                      handleLogout();
                    }}
                    className="profile-menu-item logout-btn"
                  >
                    {logoutSvg}
                    Logout
                  </button>
                </div>
              )}
              <button onClick={handleLogout} className="logout-btn-navbar">
                {logoutSvg}
                Logout
              </button>
            </div>
          </div>
          <button
            className="mobile-menu-toggle"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            ☰
          </button>
        </div>

        {/* Inline Modal for ExportToCoda */}
        {isModalOpen && (
          <div className="Navbar-modal-overlay">
            <div className="Navbar-modal-content">
              <button
                className="modal-close"
                onClick={() => setIsModalOpen(false)}
              >
                &times;
              </button>
              <ExportToCoda accounts={expandUrls} />
            </div>
          </div>
        )}
      </nav>
    );
  }

  // Navbar for other authenticated pages
  return (
    <nav className="navbar">
      <div
        className="nav-logo-container"
        style={{ display: isMobileMenuOpen ? "none" : "block" }}
      >
        <Link to="/" onClick={handleHomeClick}>
          <img src={logo} alt="Logo" className="nav-logo" />
        </Link>
      </div>
      <div className="nav-items-container">
        <div className={`nav-links ${isMobileMenuOpen ? "open" : ""}`}>
          <div className="mobile-nav-header  mobile-only">
            <Link to="/" onClick={handleHomeClick}>
              <img src={logo} alt="Logo" className="nav-logo" />
            </Link>
          </div>
          <Link to="/BankAccountsData" className="nav-link">
            {backToAccountsSvg}
            Back to Accounts
          </Link>
          <button onClick={handleLogout} className="logout-btn-navbar">
            {logoutSvg}
            Logout
          </button>
        </div>
        <button
          className="mobile-menu-toggle"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          ☰
        </button>
      </div>
    </nav>
  );
};

export default NavBar;
