import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";

import Signup from "./components/signup/Signup";
import Login from "./components/login/Login";
import ConnectToBank from "./components/banks/ConnectToBank";
import BankAccountsData from "./components/BankData/BankAccountsData";
import ViewSettingsPage from "./components/settings/ViewSettingsPage";
import LandingPage from "./components/LandingPage/LandingPage"; // Import the new LandingPage component
import ExcelInstructions from "./components/ExcelInstructions/ExcelInstructions"; // Import the new ExcelInstructions component
import DeleteAccount from "./components/pages/DeleteAccount";
import TermsAndPrivacy from "./components/TermsAndPrivacy/TermsAndPrivacy"; // Import the new TermsAndPrivacy component
import Contact from "./components/Contact/Contact"; // Import the new Contact component
import NavBar from "./components/Navbar/NavBar"; // Import the new NavBar component
import Footer from "./components/Footer/Footer"; // Import the new Footer component
import LoginDemo from "./components/login/LoginDemo";

function App() {
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const [userId, setUserId] = useState(localStorage.getItem("userId") || null);
  const [accountsID, setAccountsID] = useState(null);
  const [IBAN, setIBAN] = useState(null);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showConnectionLimits, setShowConnectionLimits] = useState(true);
  const [showCopyUrlSection, setShowCopyUrlSection] = useState(true);
  const [requisitionLength, setRequisitionLength] = useState(null);
  const [amountOfRequisitions, setAmountOfRequisitions] = useState(null);
  const [isRequisitionLimitReached, setIsRequisitionLimitReached] =
    useState(false);
  const [expandUrls, setExpandUrls] = useState(false);
  const [
    MayGetExtraUrlForEachAccountNumberForExcel,
    setMayGetExtraUrlForEachAccountNumberForExcel,
  ] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedRequisitionLength = localStorage.getItem("Requisition Length");
    const storedAmountOfRequisitions = localStorage.getItem(
      "amountOfRequisitions"
    );
    const storedMayGetExtraUrlForEachAccountNumberForExcel =
      localStorage.getItem("getExtraUrlForEachAccountNumberForExcel") ===
      "true";

    setRequisitionLength(storedRequisitionLength);
    setAmountOfRequisitions(storedAmountOfRequisitions);
    setIsRequisitionLimitReached(
      storedAmountOfRequisitions >= storedRequisitionLength
    );
    setMayGetExtraUrlForEachAccountNumberForExcel(
      storedMayGetExtraUrlForEachAccountNumberForExcel
    );
    setLoading(false);
  }, []);

  useEffect(() => {
    if (userId) {
      const storedRequisitionLength =
        localStorage.getItem("Requisition Length");
      const storedAmountOfRequisitions = localStorage.getItem(
        "amountOfRequisitions"
      );
      const storedMayGetExtraUrlForEachAccountNumberForExcel =
        localStorage.getItem("getExtraUrlForEachAccountNumberForExcel") ===
        "true";

      setRequisitionLength(storedRequisitionLength);
      setAmountOfRequisitions(storedAmountOfRequisitions);
      setIsRequisitionLimitReached(
        storedAmountOfRequisitions >= storedRequisitionLength
      );
      setMayGetExtraUrlForEachAccountNumberForExcel(
        storedMayGetExtraUrlForEachAccountNumberForExcel
      );
    }
  }, [userId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <NavBar
        showCopyUrlSection={showCopyUrlSection}
        showConnectionLimits={showConnectionLimits}
        requisitionLength={requisitionLength}
        amountOfRequisitions={amountOfRequisitions}
        isRequisitionLimitReached={isRequisitionLimitReached}
        accountsID={accountsID}
        setShowProfileMenu={setShowProfileMenu}
        showProfileMenu={showProfileMenu}
        setExpandUrls={setExpandUrls}
        expandUrls={expandUrls}
        MayGetExtraUrlForEachAccountNumberForExcel={
          MayGetExtraUrlForEachAccountNumberForExcel
        }
        setMayGetExtraUrlForEachAccountNumberForExcel={
          setMayGetExtraUrlForEachAccountNumberForExcel
        }
        setRequisitionLength={setRequisitionLength}
        setAmountOfRequisitions={setAmountOfRequisitions}
        setIsRequisitionLimitReached={setIsRequisitionLimitReached}
      />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/Login"
          element={
            <Login
              userData={userData}
              setUserData={setUserData}
              setUserId={setUserId}
            />
          }
        />
        <Route path="/Signup" element={<Signup />} />
        <Route
          path="/ConnectToBank"
          element={
            <ConnectToBank
              userData={userData}
              setUserData={setUserData}
              accountsID={accountsID}
              setAccountsID={setAccountsID}
            />
          }
        />
        <Route
          path="/BankAccountsData"
          element={
            <BankAccountsData
              userId={userId}
              setAccountsID={setAccountsID}
              setIBAN={setIBAN}
              setExpandUrls={setExpandUrls}
              setMayGetExtraUrlForEachAccountNumberForExcel={
                setMayGetExtraUrlForEachAccountNumberForExcel
              }
            />
          }
        />
        <Route path="/ViewSettingsPage" element={<ViewSettingsPage />} />
        <Route
          path="/excel-instructions"
          element={<ExcelInstructions IBAN={IBAN} userId={userId} />}
        />
        <Route path="/delete-account" element={<DeleteAccount />} />
        <Route path="/terms-and-privacy" element={<TermsAndPrivacy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login-demo" element={<LoginDemo />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
