import React, { useState, useEffect } from "react";
import styles from "./ExportToCoda.module.css";
import ExcelJS from "exceljs";

import config from "../../config/config";

const ExportToCoda = ({ accounts }) => {
  const [IBANWithTransactions, setIBANWithTransactions] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [filterByDate, setFilterByDate] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [exportFormat, setExportFormat] = useState("");
  const [exported, setExported] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [noTransactionsMessage, setNoTransactionsMessage] = useState("");

  const useId = localStorage.getItem("userId");

  useEffect(() => {
    if (selectedAccount) {
      fetchTransactions();
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (!selectedAccount) {
      setFilteredTransactions([]);
      return;
    }

    // Get the full transaction list from cached data
    const cachedData = IBANWithTransactions.find(
      (item) => item.iban === selectedAccount
    );

    if (!cachedData || !cachedData.transactions.length) return;

    let transactions = [...cachedData.transactions]; // Clone original transactions

    // Convert all transaction dates to Date objects for consistency
    transactions = transactions.map((tx) => ({
      ...tx,
      Date: new Date(tx.Date),
    }));

    // Filter by start date if selected
    if (startDate) {
      const start = new Date(startDate);
      transactions = transactions.filter((tx) => tx.Date >= start);
    }

    // Filter by end date if selected
    if (endDate) {
      const end = new Date(endDate);
      transactions = transactions.filter((tx) => tx.Date <= end);
    }

    setFilteredTransactions(transactions);

    // Set message if no transactions found
    if (transactions.length === 0) {
      setNoTransactionsMessage(
        "No transactions found for the selected start date."
      );
      setTimeout(() => {
        setNoTransactionsMessage("");
      }, 3000); // Remove message after 2 seconds
    } else {
      setNoTransactionsMessage(""); // Reset message if transactions are found
    }
  }, [selectedAccount, startDate, endDate, IBANWithTransactions]);

  const handleAccountChange = (e) => {
    setSelectedAccount(e.target.value); // This will trigger the useEffect
  };

  const fetchTransactions = async () => {
    setLoading(true);
    setError(null);

    try {
      if (!selectedAccount) {
        setFilteredTransactions([]);
        return;
      }

      // Check if transactions for the selected account are already cached
      const cachedData = IBANWithTransactions.find(
        (item) => item.iban === selectedAccount
      );

      let transactions;
      if (cachedData) {
        transactions = cachedData.transactions;
      } else {
        const response = await fetch(
          `${config.URL_PROD}/api/v1/transactions/${useId}/${selectedAccount}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error(
            `Failed to fetch transactions: ${response.statusText}`
          );
        }
        const result = await response.json();
        transactions = result.sorted;

        // Normalize transaction dates
        transactions = transactions.map((transaction) => ({
          ...transaction,
          Date: new Date(transaction.Date),
        }));

        // Update IBANWithTransactions with the fetched data
        setIBANWithTransactions((prev) => [
          ...prev,
          { iban: selectedAccount, transactions },
        ]);
      }

      setFilteredTransactions(transactions);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleExportFormatChange = (e) => {
    setExportFormat(e.target.value);
  };

  const validateDateRange = (start, end) => {
    const today = new Date();
    const startDate = new Date(start);
    const endDate = new Date(end);

    if (startDate > today) {
      setStartDateError("Start date cannot be in the future.");
      return false;
    } else {
      setStartDateError("");
    }

    if (endDate > today) {
      setEndDateError("End date cannot be in the future.");
      return false;
    } else {
      setEndDateError("");
    }

    if (startDate && endDate && startDate > endDate) {
      setStartDateError("Start date cannot be after end date.");
      setEndDateError("End date cannot be before start date.");
      return false;
    } else {
      setStartDateError("");
      setEndDateError("");
    }

    return true;
  };

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);

    if (validateDateRange(newStartDate, endDate)) {
      setStartDateError("");
    }
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);

    if (validateDateRange(startDate, newEndDate)) {
      setEndDateError("");
    }
  };

  const handleExport = async () => {
    if (!selectedAccount) {
      alert("Please select an account to export.");
      return;
    }

    let fileContent;
    let fileType;
    let fileName;

    switch (exportFormat) {
      case "excel":
        fileContent = await convertToExcel(filteredTransactions); // Await here
        fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        fileName = "transactions.xlsx";
        break;
      case "csv":
        fileContent = convertToCSV(filteredTransactions);
        fileType = "text/csv";
        fileName = "transactions.csv";
        break;
      case "coda":
      default:
        fileContent = convertToCoda(filteredTransactions);
        fileType = "text/plain";
        fileName = "transactions.coda";
        break;
    }

    const blob = new Blob([fileContent], { type: fileType });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    link.click();

    URL.revokeObjectURL(url);
    setExported(true);
    setSelectedAccount(""); // Reset selected account after export
    setExportFormat(""); // Reset export format to default
    // Reset date filters
    setStartDate("");
    setEndDate("");
    setFilterByDate(false);

    // Hide the success message after 3 seconds and reset the export format
    setTimeout(() => {
      setExported(false);
    }, 3000);
  };

  const convertToExcel = async (transactions) => {
    if (!transactions || transactions.length === 0) {
      throw new Error("No transactions available to export.");
    }

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Transactions");

    // Add headers (excluding the first column)
    const headers = Object.keys(transactions[0]).slice(1);
    worksheet.addRow(headers);

    // Add transaction data (excluding the first column)
    const rows = transactions.map((transaction) => {
      const formattedTransaction = { ...transaction };
      if (formattedTransaction.Date instanceof Date) {
        formattedTransaction.Date =
          formattedTransaction.Date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
      }
      return Object.values(formattedTransaction).slice(1);
    });
    rows.forEach((row) => worksheet.addRow(row));

    // Style the header row
    const headerRow = worksheet.getRow(1);
    headerRow.font = { bold: true, color: { argb: "FFFFFF" } };
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "009A44" },
      };
    });

    // Apply alternating row styles
    worksheet.eachRow((row, rowIndex) => {
      if (rowIndex > 1 && rowIndex % 2 === 0) {
        row.eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "D9F4D6" }, // Light Green for even rows
          };
        });
      }
    });

    // Adjust column widths dynamically based on the widest field
    worksheet.columns.forEach((column, index) => {
      const columnData = [headers[index], ...rows.map((row) => row[index])];
      const maxLength = columnData.reduce(
        (max, value) => Math.max(max, value ? value.toString().length : 0),
        10 // Minimum width
      );
      column.width = maxLength + 2; // Add padding
    });

    // Apply auto-filter to the header row
    worksheet.autoFilter = {
      from: { row: 1, column: 1 },
      to: { row: 1, column: headers.length },
    };

    // Generate Excel file buffer
    const buffer = await workbook.xlsx.writeBuffer();
    return buffer;
  };

  const convertToCSV = (transactions) => {
    // Exclude the first column from headers and rows
    const headers = Object.keys(transactions[0]).slice(1).join(",");
    const rows = transactions
      .map((t) => Object.values(t).slice(1).join(","))
      .join("\n");
    return `${headers}\n${rows}`;
  };

  const generateCoda = (data) => {
    let codaLines = [];
    let sequence = 1;

    // Record 0: Header
    const today = new Date().toISOString().slice(2, 10).replace(/-/g, ""); // e.g., 250326
    const header = `${pad(
      `0${today}${data.account.bankCode}${data.account.iban.slice(0, 12)}`,
      128
    )}`;
    codaLines.push(header);

    // Record 1: Old Balance
    const oldBalanceAmount = padZero(
      Math.round(data.account.startingBalance * 100),
      15
    ); // Convert to cents
    const oldBalance = `${pad(
      `1${padZero(sequence++, 4)}0${data.account.iban}${oldBalanceAmount}0`,
      128
    )}`;
    codaLines.push(oldBalance);

    // Record 2: Transactions
    data.transactions.forEach((tx) => {
      const seq = padZero(sequence++, 4);
      const date = new Date(tx.createdDate)
        .toISOString()
        .slice(2, 10)
        .replace(/-/g, ""); // e.g., 250325
      const amount = padZero(Math.round(tx.amount * 100), 15); // Convert to cents
      const ref = pad(tx.reference || tx.paymentId, 21);
      const transaction = `21${seq}0${date}${amount}0${ref}`.padEnd(128, " ");
      codaLines.push(transaction);
    });

    // Record 8: New Balance
    const totalAmount =
      data.account.startingBalance +
      data.transactions.reduce((sum, tx) => sum + tx.amount, 0);
    const newBalanceAmount = padZero(Math.round(totalAmount * 100), 15);
    const newBalance = `${pad(
      `8${padZero(sequence, 4)}${today}${newBalanceAmount}0`,
      128
    )}`;
    codaLines.push(newBalance);

    return codaLines.join("\n");
  };

  const convertToCoda = (transactions) => {
    const goCardlessData = {
      transactions: transactions.map((tx) => ({
        paymentId: tx.TransactionId || "Unknown",
        amount: tx.Amount,
        createdDate: tx.Date,
        customerName: tx.Merchant || "Unknown",
        reference: tx.Mededeling || "No Reference",
      })),
      account: {
        iban: "BE68539007547034", // Example IBAN
        bankCode: "KBC", // Example bank code
        startingBalance: 1000.0, // Example starting balance
      },
    };

    return generateCoda(goCardlessData);
  };

  // Utility functions for padding
  const pad = (str, length, char = " ") => String(str).padEnd(length, char);
  const padZero = (str, length) => String(str).padStart(length, "0");

  return (
    <div className={styles.container}>
      <h2 className={styles.h2}>Export Transactions</h2>
      <p className={styles.disclaimerNote}>
        <em>Note: The data is not live, shows only data till now. </em>
        <em>
          For a one click to update sheet, Fallow the{" "}
          <a href="/excel-instructions">Excel Instructure</a>
        </em>
      </p>
      {/* Account Selector */}
      <label className={styles.label}>
        Select Account:
        <select
          value={selectedAccount}
          onChange={handleAccountChange}
          className={styles.select}
        >
          <option value="">-- Select Account --</option>
          {accounts?.map((account) => (
            <option key={account} value={account}>
              {account}
            </option>
          ))}
        </select>
      </label>
      {/* Export Format Selector */}
      <label className={styles.label}>
        Select Export Format:
        <select
          value={exportFormat}
          onChange={handleExportFormatChange}
          className={styles.select}
        >
          <option value="">-- Select Format --</option>
          <option value="excel">Excel</option>
          <option value="csv">CSV</option>
          <option value="coda">CODA</option>
        </select>
      </label>
      {/* Date Filter Toggle */}
      <div className={styles.dateFilterToggle}>
        <label className={styles.label}>
          <input
            type="checkbox"
            checked={filterByDate}
            onChange={() => {
              setFilterByDate(!filterByDate);
              if (filterByDate && (startDate || endDate) && selectedAccount) {
                setStartDate("");
                setEndDate("");
                setFilteredTransactions(
                  IBANWithTransactions.find(
                    (item) => item.iban === selectedAccount
                  )?.transactions || []
                );
              }
            }}
          />{" "}
          Filter by Date
        </label>
      </div>
      {/* Date Filter Fields */}
      {filterByDate && (
        <div className={styles.dateFilter}>
          <label className={styles.label}>
            Start Date:
            <input
              className={styles.dateInput}
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
            />
            {startDateError && (
              <span className={styles.errorNote}>{startDateError}</span>
            )}
          </label>
          <label className={styles.label}>
            End Date:
            <input
              className={styles.dateInput}
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
            />
            {endDateError && (
              <span className={styles.errorNote}>{endDateError}</span>
            )}
          </label>
        </div>
      )}
      {/* Export Button */}
      <button
        disabled={loading || filteredTransactions.length < 1}
        onClick={handleExport}
        className={`${styles.exportButton} ${
          selectedAccount && exportFormat && filteredTransactions.length > 0
            ? styles.active
            : styles.disabled
        }`}
        title={
          filteredTransactions.length < 1
            ? "0 transactions in this date range"
            : ""
        }
      >
        Export Transactions
      </button>
      {/* Export Confirmation */}
      {loading && <p>Loading transactions...</p>}
      {error && <p>Error: {error}</p>}
      {exported && (
        <p className={styles.success}>Transactions exported successfully!</p>
      )}
      {/* Custom Message when no transactions are found */}
      {noTransactionsMessage && (
        <div className={styles.customMessage}>{noTransactionsMessage}</div>
      )}
      {/* Red disclaimer for CODA */}
      <p className={styles.disclaimer}>
        Note: CODA export is not working yet correctly.
      </p>
    </div>
  );
};

export default ExportToCoda;
