import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Modal from "../common/Modal/Modal";
import styles from "./login.module.css";
import config from "../../config/config";

const Login = ({ userData, setUserData, setUserId }) => {
  const [response, setResponse] = useState(null);
  const [errMessage, setErrMessage] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (response) {
      const timer = setTimeout(() => {
        setResponse(null);
      }, 8000);
      return () => clearTimeout(timer);
    }
  }, [response]);

  const userDataSubmited = async (e) => {
    e.preventDefault();
    const results = await fetch(`${config.URL_PROD}/api/v1/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    // Check if the response status is 401 (Unauthorized)
    if (results.status === 401) {
      const data = await results.json();
      setErrMessage(data.message);
      setShowErrorModal(true);
      return;
    }

    // if status 400 than reload the page
    if (results.status === 400) {
      window.location.reload();
      setErrMessage("Something went wrong, please try again");
    }

    // Check if the response status is 429 (Too Many Requests)
    if (results.status === 429) {
      const text = await results.text();
      setErrMessage(text);
      return;
    }

    // Try to parse the response as JSON
    const data = await results.json().catch(() => null);

    if (
      data?.error ||
      results.statusText === "Internal Server Error" ||
      results.statusText === "Unauthorized" ||
      results.statusText === "Bad Request" ||
      !data
    ) {
      setErrMessage(
        data?.message || data?.error || "Unexpected error occurred"
      );
      setShowErrorModal(true);
      return;
    }

    // Save UserID from the data.requisition.User in local storage
    const userId = data?.User;
    localStorage.setItem("userId", userId);
    localStorage.setItem("Requisition Length", data?.Requisition);
    localStorage.setItem("amountOfRequisitions", data?.amountOfRequisitions);
    localStorage.setItem(
      "getExtraUrlForEachAccountNumberForExcel",
      data?.getExtraUrlForEachAccountNumberForExcel
    );
    sessionStorage.setItem("email", data.email);
    sessionStorage.setItem("username", data.username);

    setResponse(data);
    setUserId(userId);

    if (data.token && data.Requisition) {
      localStorage.setItem("token", data.token);
      return navigate("/BankAccountsData");
    }

    if (data.token) {
      localStorage.setItem("token", data.token);
      return navigate("/ConnectToBank");
    }
  };

  const forgotPassword = async (e) => {
    // stop the form from submitting
    e.preventDefault();

    try {
      const results = await fetch(`${config.URL_PROD}/generate-link`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (!results.ok) {
        throw new Error("Failed to send reset password link");
      }

      const responseOk = await results.text();
      console.log("responseOk", responseOk);
      setResponse({ message: responseOk });
    } catch (error) {
      console.error("Error sending reset password link:", error);
      setErrMessage("Failed to send reset password link");
    }
  };

  return (
    <div className={styles.loginPage}>
      <Modal
        isOpen={showErrorModal}
        onClose={() => {
          setShowErrorModal(false);
          setErrMessage(null);
        }}
        type="error"
        title="Login Error"
        message={errMessage}
        cancelText="Close"
        showTimer={true}
        duration={6000}
        animationType="slide"
        onTimerComplete={() => {
          if (errMessage?.includes("has been deleted")) {
            navigate("/Signup");
          }
        }}
        onCancel={() => {
          setShowErrorModal(false);
          setErrMessage(null);
          if (errMessage?.includes("has been deleted")) {
            navigate("/Signup");
          }
        }}
      />

      <div className={styles.loginContainer}>
        <div className={styles.loginBox}>
          <div className={styles.logoSection}>
            <h1>Bank Accounts in Excel</h1>
            <p className={styles.subtitle}>
              Welcome back! Please login to continue.
            </p>
          </div>

          {response && (
            <p className={styles.responseMessage}>{response.message}</p>
          )}

          <form onSubmit={userDataSubmited} className={styles.loginForm}>
            <div className={styles.formGroup}>
              <label htmlFor="email">Email address</label>
              <input
                id="email"
                className={styles.input}
                type="email"
                name="email"
                required
                placeholder="Enter your email"
                onChange={(e) =>
                  setUserData({ ...userData, email: e.target.value })
                }
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="password">Password</label>
              <input
                id="password"
                className={styles.input}
                type="password"
                name="password"
                required
                placeholder="Enter your password"
                onChange={(e) =>
                  setUserData({ ...userData, password: e.target.value })
                }
              />
            </div>

            <div className={styles.formActions}>
              <button type="submit" className={styles.loginButton}>
                Sign in
              </button>
            </div>

            <div className={styles.formFooter}>
              <NavLink to="/Signup" className={styles.signupLink}>
                Don't have an account? Sign up
              </NavLink>
              <button
                onClick={forgotPassword}
                className={styles.forgotPasswordLink}
                type="button"
              >
                Forgot password?
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
